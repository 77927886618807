@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

body {
    background: #BBBBBB;
}

.header {
    background-color: #111;
    height: 70px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__left {
        flex-direction: row;
        display: flex;

        &_link {
            font-size: 16px;
            line-height: 70px;
            text-decoration: none;
            padding-left: 10px;
            padding-right: 10px;
            color: #FFF;

            &:hover {
                background-color: rgba(255, 255, 255, 0.25);
            }
        }

        .logo {
            background-image: url(../src/images/logo.jpg);
            border-radius: 50%;
        }
    }

    &__right {
        flex-direction: row;
        display: flex;

        &_VK {
            background-image: url(../src/images/VK.png);
        }

        &_talant {
            background-image: url(../src/images/Talant.png);
        }
    }

    .logo {
        margin: 10px;
        height: 50px;
        width: 50px;
        display: flex;
        background-position: center;
        background-size: contain;

        &:hover {
            filter: brightness(1.2);
        }
    }
}

.container {
    margin: 0 auto;
    max-width: 700px;
}

.main {
    background-color: #eee;
    box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.3);

    h2 {
        text-align: center;
        padding-top: 30px;
        font-size: 32px;
        color: #111;
        padding-bottom: 30px;
    }

    .info {
        position: relative;
        padding-top: 70px;

        p {
            width: 350px;
            margin-left: 70px;
            padding-bottom: 70px;
        }

        img {
            position: absolute;
            top: 120px;
            right: 50px;
            width: 200px;
        }
    }

    .news {
        background-color: #ccc;
        margin-top: 60px;
        position: relative;
        z-index: 2;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            overflow: hidden;
            position: relative;
        }

        &__slider {
            display: flex;
        }

        &__arrow-left {
            border-radius: 0 15px 15px 0;
            position: absolute;
            left: 0;
            width: 40px;
            height: 300px;
            z-index: 1;
            cursor: pointer;


            &:hover {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        &__arrow-right {
            border-radius: 15px 0 0 15px;
            position: absolute;
            right: 0;
            width: 40px;
            height: 300px;
            z-index: 1;
            cursor: pointer;


            &:hover {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        &__item {
            display: flex;
            background: #ddd;
            border-radius: 15px;
            width: 260px;
            height: 300px;
            margin: 0 30px;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 30px;
            // transition: 0.1s;
            cursor: pointer;

            &:hover {
                filter: brightness(1.1);
            }

            img {
                width: 260px;
                height: 260px;
                display: flex;
                border-radius: 15px 15px 0 0;
                object-fit: cover;
            }

            h4 {
                text-align: center;
                line-height: 40px;
            }
        }
    }

    .newsPopup {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        transition: 1s;

        &__BG {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(7.5px);
            position: fixed;
            top: 0;
            left: 0;
        }

        &__menu {
            width: 500px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 15px;
            background: #EEE;
            box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.25);

            &_container {
                position: relative;
                width: 100%;
                height: 100%;

                .newsPopup__close {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    width: 37px;
                    height: 37px;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                h2 {
                    max-width: calc(100% - 194px);
                    margin: auto;
                }

                p {
                    padding: 0 20px;
                    padding-bottom: 20px;
                }

                img {
                    border-radius: 0px 0px 15px 15px;
                    width: 100%;
                    max-height: 800px;
                    display: block;
                    object-fit: contain;
                }
            }
        }
    }

    .coaches {
        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding-bottom: 60px;

            .coach {
                width: 275px;
                background: #D9D9D9;
                border-radius: 15px;

                h3 {
                    text-align: center;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                }

                img {
                    border-radius: 15px 15px 0px 0px;
                    width: 100%;
                    height: 275px;
                    object-fit: cover;
                }
            }
        }
    }
}

.social {
    background-color: #ccc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left {
        display: flex;

        .logo {
            height: 60px;
            width: 60px;
            margin: 20px;
            margin-right: 0;
            display: inline-block;
            background-size: contain;
            background-image: url(../src/images/logo.jpg);
            border-radius: 50%;
            box-sizing: border-box;
        }

        .VK {
            background-image: url(../src/images/VK.png);
            border-radius: 0;
        }

        .talant {
            background-image: url(../src/images/Talant.png);
            border-radius: 0;
        }

        *:hover {
            filter: brightness(1.2);
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 20px;

        p {
            line-height: 24px;
        }
    }
}

.footer iframe {
    width: 100%;
}

@media (max-width: 600px) {
    .header {
        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__left {
            &_link {
                display: none;
            }
        }
    }

    .main {
        .info {
            position: relative;

            p {
                width: 90%;
                margin-left: 5%;
                padding-bottom: 150px;
            }

            img {
                position: absolute;
                top: auto;
                bottom: 0;
                left: 15%;

                width: 70%;
                opacity: 25%;
            }
        }

        .news {
            margin-top: 0;

            &__slider_container {
                transform: translateX(215px);
            }

            &__item {
                margin: 0 85px;
                margin-bottom: 30px;
            }
        }

        .newsPopup__menu {
            width: 95%;
        }

        .coaches__container {
            display: flex;
            flex-direction: column;

            .coach {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 260px;
                margin: auto;

                &:first-child {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .footer {
        .container {
            .social {
                flex-direction: column;

                &__left {
                    justify-content: center;

                    .logo {
                        margin: 20px;
                    }
                }

                &__right {
                    padding: 0;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }
    }
}