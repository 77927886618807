@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background: #BBBBBB;
}

.header {
  background-color: #111;
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header__left {
  flex-direction: row;
  display: flex;
}
.header__left_link {
  font-size: 16px;
  line-height: 70px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  color: #FFF;
}
.header__left_link:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.header__left .logo {
  background-image: url(../src/images/logo.jpg);
  border-radius: 50%;
}
.header__right {
  flex-direction: row;
  display: flex;
}
.header__right_VK {
  background-image: url(../src/images/VK.png);
}
.header__right_talant {
  background-image: url(../src/images/Talant.png);
}
.header .logo {
  margin: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  background-position: center;
  background-size: contain;
}
.header .logo:hover {
  filter: brightness(1.2);
}

.container {
  margin: 0 auto;
  max-width: 700px;
}

.main {
  background-color: #eee;
  box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.3);
}
.main h2 {
  text-align: center;
  padding-top: 30px;
  font-size: 32px;
  color: #111;
  padding-bottom: 30px;
}
.main .info {
  position: relative;
  padding-top: 70px;
}
.main .info p {
  width: 350px;
  margin-left: 70px;
  padding-bottom: 70px;
}
.main .info img {
  position: absolute;
  top: 120px;
  right: 50px;
  width: 200px;
}
.main .news {
  background-color: #ccc;
  margin-top: 60px;
  position: relative;
  z-index: 2;
}
.main .news__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative;
}
.main .news__slider {
  display: flex;
}
.main .news__arrow-left {
  border-radius: 0 15px 15px 0;
  position: absolute;
  left: 0;
  width: 40px;
  height: 300px;
  z-index: 1;
  cursor: pointer;
}
.main .news__arrow-left:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.main .news__arrow-right {
  border-radius: 15px 0 0 15px;
  position: absolute;
  right: 0;
  width: 40px;
  height: 300px;
  z-index: 1;
  cursor: pointer;
}
.main .news__arrow-right:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.main .news__item {
  display: flex;
  background: #ddd;
  border-radius: 15px;
  width: 260px;
  height: 300px;
  margin: 0 30px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;
}
.main .news__item:hover {
  filter: brightness(1.1);
}
.main .news__item img {
  width: 260px;
  height: 260px;
  display: flex;
  border-radius: 15px 15px 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.main .news__item h4 {
  text-align: center;
  line-height: 40px;
}
.main .newsPopup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 1s;
}
.main .newsPopup__BG {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  position: fixed;
  top: 0;
  left: 0;
}
.main .newsPopup__menu {
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  background: #EEE;
  box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.25);
}
.main .newsPopup__menu_container {
  position: relative;
  width: 100%;
  height: 100%;
}
.main .newsPopup__menu_container .newsPopup__close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 37px;
  height: 37px;
  cursor: pointer;
}
.main .newsPopup__menu_container .newsPopup__close:hover {
  opacity: 0.7;
}
.main .newsPopup__menu_container h2 {
  max-width: calc(100% - 194px);
  margin: auto;
}
.main .newsPopup__menu_container p {
  padding: 0 20px;
  padding-bottom: 20px;
}
.main .newsPopup__menu_container img {
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  max-height: 800px;
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
}
.main .coaches__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 60px;
}
.main .coaches__container .coach {
  width: 275px;
  background: #D9D9D9;
  border-radius: 15px;
}
.main .coaches__container .coach h3 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.main .coaches__container .coach img {
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  height: 275px;
  -o-object-fit: cover;
     object-fit: cover;
}

.social {
  background-color: #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.social__left {
  display: flex;
}
.social__left .logo {
  height: 60px;
  width: 60px;
  margin: 20px;
  margin-right: 0;
  display: inline-block;
  background-size: contain;
  background-image: url(../src/images/logo.jpg);
  border-radius: 50%;
  box-sizing: border-box;
}
.social__left .VK {
  background-image: url(../src/images/VK.png);
  border-radius: 0;
}
.social__left .talant {
  background-image: url(../src/images/Talant.png);
  border-radius: 0;
}
.social__left *:hover {
  filter: brightness(1.2);
}
.social__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}
.social__right p {
  line-height: 24px;
}

.footer iframe {
  width: 100%;
}

@media (max-width: 600px) {
  .header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header__left_link {
    display: none;
  }
  .main .info {
    position: relative;
  }
  .main .info p {
    width: 90%;
    margin-left: 5%;
    padding-bottom: 150px;
  }
  .main .info img {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 15%;
    width: 70%;
    opacity: 25%;
  }
  .main .news {
    margin-top: 0;
  }
  .main .news__slider_container {
    transform: translateX(215px);
  }
  .main .news__item {
    margin: 0 85px;
    margin-bottom: 30px;
  }
  .main .newsPopup__menu {
    width: 95%;
  }
  .main .coaches__container {
    display: flex;
    flex-direction: column;
  }
  .main .coaches__container .coach {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
    margin: auto;
  }
  .main .coaches__container .coach:first-child {
    margin-bottom: 30px;
  }
  .footer .container .social {
    flex-direction: column;
  }
  .footer .container .social__left {
    justify-content: center;
  }
  .footer .container .social__left .logo {
    margin: 20px;
  }
  .footer .container .social__right {
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=index.css.map */